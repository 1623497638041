import { inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from './common.service';
interface IActionIarr {
  url: string;
  actionId: number | string;
  type: string;
}
interface MainMenu {
  value: boolean;
  action: Action[];
  menu_id: number;
  sub_menu: SubMenu[];
  main_menu: string;
}
interface SubMenu {
  value: boolean;
  action: Action[];
  sub_menu_id: number;
  sub_menu_name: string;
}

interface Action {
  name: string;
  value: boolean;
  action_id: number;
}
@Injectable({
  providedIn: 'root',
})
export class AclService {
  public actionIdArr: IActionIarr[] = [
    { url: 'policy/add-edit', actionId: 25, type: 'Add' },
    { url: 'policy/add-edit', actionId: 26, type: 'Edit' },
    { url: 'policy/list', actionId: 28, type: 'settings' },
    { url: 'policy/list', actionId: 80, type: '' },
    { url: 'policy/delete', actionId: 27, type: '' },
    { url: 'policy/update-order', actionId: 26, type: '' },
    { url: 'service-type/add-edit', actionId: 29, type: 'Add' },
    { url: 'service-type/add-edit', actionId: 30, type: 'Edit' },
    { url: 'service-type/list', actionId: 32, type: 'service' },
    { url: 'service-type/list', actionId: 37, type: 'milestone' },
    { url: 'service-type/delete', actionId: 31, type: '' },
    { url: 'project-type/add-edit', actionId: 33, type: 'Add' },
    { url: 'project-type/add-edit', actionId: 34, type: 'Edit' },
    { url: 'project-type/list', actionId: 36, type: 'settings' },
    { url: 'project-type/delete', actionId: 35, type: '' },
    { url: 'project-type/copy', actionId: 1, type: '' },
    { url: 'milestone/add-edit', actionId: 38, type: 'Add' },
    { url: 'milestone/add-edit', actionId: 39, type: 'Edit' },
    { url: 'milestone/list', actionId: 37, type: '' },
    { url: 'milestone/delete', actionId: 40, type: '' },
    { url: 'ilestone/update-order', actionId: 39, type: '' },
    { url: 'onboarding/add-edit', actionId: 41, type: 'Add' },
    { url: 'onboarding/add-edit', actionId: 42, type: 'Edit' },
    { url: 'onboarding/list', actionId: 44, type: 'settings' },
    { url: 'onboarding/delete', actionId: 43, type: '' },
    { url: 'onboarding/update-order', actionId: 42, type: '' },
    { url: 'evaluation-form/add-edit', actionId: 51, type: 'Add' }, // testing pending
    { url: 'evaluation-form/add-edit', actionId: 52, type: 'Edit' }, // testing pending
    { url: 'evaluation-form/list', actionId: 54, type: '' },
    { url: 'evaluation-form/delete', actionId: 53, type: '' },
    { url: 'evaluation-form/publish', actionId: 56, type: '' },
    { url: 'evaluation-form/performance-add-edit', actionId: 51, type: 'Add' }, // testing pending
    { url: 'evaluation-form/performance-add-edit', actionId: 52, type: 'Edit' }, // testing pending
    { url: 'evaluation-form/performance-list', actionId: 54, type: '' },
    { url: 'evaluation-form/performance-delete', actionId: 52, type: '' },
    { url: 'evaluation-form/copy', actionId: 55, type: '' },
    { url: 'drip/add-edit', actionId: 22, type: 'Add' },
    { url: 'drip/add-edit', actionId: 23, type: 'Edit' },
    { url: 'drip/list', actionId: 21, type: '' },
    { url: 'drip/delete', actionId: 82, type: '' },
    { url: 'user/inv-user', actionId: 1, type: 'Add' },
    { url: 'user/inv-user', actionId: 2, type: 'Edit' },
    { url: 'user/list', actionId: 4, type: 'user' },
    { url: 'user/delete', actionId: 3, type: '' },
    { url: 'user/resend-inv', actionId: 5, type: '' },
    { url: 'client-management/add-edit-client', actionId: 58, type: 'Add' },
    { url: 'client-management/add-edit-client', actionId: 59, type: 'Edit' },
    { url: 'client-management/client-list', actionId: 67, type: '' },
    { url: 'client-management/client-delete', actionId: 60, type: '' },
    { url: 'client-management/add-edit-contact', actionId: 62, type: 'Add' },
    { url: 'client-management/add-edit-contact', actionId: 63, type: 'Edit' },
    { url: 'client-management/contact-list', actionId: 83, type: '' },
    { url: 'client-management/contact-delete', actionId: 84, type: '' },
    { url: 'evaluation/get-evaluation-form', actionId: 17, type: 'evaluation' },
    { url: 'evaluation/get-evaluation-form', actionId: 117, type: 'null' },
    { url: 'evaluation/add-edit', actionId: 18, type: 'Add' },
    { url: 'evaluation/add-edit', actionId: 20, type: 'Edit' },
    { url: 'evaluation/list', actionId: '117', type: 'null' },
    { url: 'website/add-edit', actionId: 68, type: 'Add' }, // testing pending her onwoards
    { url: 'website/add-edit', actionId: 69, type: 'Edit' },
    { url: 'website/list', actionId: 85, type: 'website' },
    { url: 'website/delete', actionId: 70, type: '' },
    { url: 'website/view-milestones', actionId: 96, type: 'web-milestone' },
    { url: 'website/update-cms', actionId: 115, type: '' },
    { url: 'website/plugin-list', actionId: 86, type: '' },
    { url: 'website/add-edit-security-patch', actionId: 115, type: '' },
    { url: 'website/delete-security-patch', actionId: 115, type: '' },
    { url: 'website/cms-list', actionId: 87, type: '' },
    { url: 'website/milestone-status-update', actionId: 71, type: '' },
    { url: 'enrollment-form/add-edit', actionId: 45, type: 'Add' },
    { url: 'enrollment-form/add-edit', actionId: 46, type: 'Edit' },
    { url: 'enrollment-form/list', actionId: 48, type: '' },
    { url: 'enrollment-form/delete', actionId: 47, type: '' },
    { url: 'enrollment-form/publish', actionId: 50, type: '' },
    { url: 'enrollment-form/element-add-edit', actionId: 45, type: 'Add' },
    { url: 'enrollment-form/element-add-edit', actionId: 46, type: 'Edit' },
    { url: 'enrollment-form/element-list', actionId: 48, type: '' },
    { url: 'enrollment-form/element-delete', actionId: 46, type: '' },
    { url: 'enrollment-form/copy', actionId: 49, type: '' },
    { url: 'enrollment-form/update-element-order', actionId: 90, type: '' },
    { url: 'enrollment/fetch-form', actionId: 15, type: '' },
    { url: 'department/list', actionId: 12, type: 'dept' },
    { url: 'department/add-edit', actionId: 9, type: 'Add' },
    { url: 'department/add-edit', actionId: 10, type: 'Edit' },
    { url: 'department/delete', actionId: 11, type: '' },
    { url: 'role/list', actionId: 93, type: 'role' },
    { url: 'role/add-edit', actionId: 6, type: 'Add' },
    { url: 'role/add-edit', actionId: 7, type: 'Edit' },
    { url: 'role/delete', actionId: 8, type: '' },
    { url: 'acl/add-edit', actionId: 14, type: '' },
    { url: 'acl/view', actionId: 13, type: '' },
    { url: 'department/list', actionId: 44, type: 'settings' },
    { url: 'website/update-virus', actionId: 115, type: '' },
    { url: 'drip/update-order', actionId: 23, type: '' },

    /** New Updated ACL ID
     * Left Menu	 */

    // Employee Onboarding
    { url: 'onboarding/list', actionId: 76, type: 'emp' },

    // Enrollment
    { url: 'user/list', actionId: 15, type: 'enrollment' },

    // Client Management
    { url: 'website/add-edit', actionId: 64, type: 'client-add' },
    { url: 'website/add-edit', actionId: 65, type: 'client-edit' },
    { url: 'website/list', actionId: 61, type: 'client' },
    { url: 'website/delete', actionId: 66, type: 'client' },
    { url: 'project-type/list', actionId: 61, type: 'client' },
    { url: 'website/cms-list', actionId: 61, type: 'client' },
    { url: 'website/technology-list', actionId: 61, type: 'website' },

    // Settings
    { url: 'milestone/update-order', actionId: 39, type: '' },

    // user management
    { url: 'department/list', actionId: 4, type: 'user' },
    { url: 'role/list', actionId: 4, type: 'user' },
    { url: 'user/managers', actionId: 1, type: 'Add' },
    { url: 'user/managers', actionId: 4, type: 'Edit' },
    // Access Control
    { url: 'role/list', actionId: 13, type: 'acl' },
    // Enrollment
    { url: 'department/list', actionId: 15, type: 'enrollment' },
    { url: 'role/list', actionId: 15, type: 'enrollment' },
    { url: 'enrollment/download', actionId: 16, type: 'enrollment' },

    // Evaluation
    { url: 'user/list', actionId: 94, type: 'evaluation' },
    { url: 'evaluation/list', actionId: 95, type: 'evaluation' },
    { url: 'department/list', actionId: 94, type: 'evaluation' },
    { url: 'role/list', actionId: 94, type: 'evaluation' },
    { url: 'evaluation/download', actionId: 19, type: 'evaluation' },

    // Website management
    { url: 'website/view-milestones', actionId: 71, type: 'web' },
    { url: 'website/plugin-list', actionId: 96, type: 'website' },

    { url: 'client-management/resend-inv', actionId: 98, type: '' },

    //plugin manager
    { url: 'website/technology-list', actionId: 99, type: 'plugin' },
    { url: 'website/plugin-list', actionId: 99, type: 'plugin' },
    { url: 'website/plugin-add-edit', actionId: 100, type: 'Add' },
    { url: 'website/plugin-add-edit', actionId: 101, type: 'Edit' },
    { url: 'website/plugin-delete', actionId: 102, type: '' },

    // cms
    { url: 'content-management/list', actionId: 103, type: '' },
    { url: 'content-management/pages', actionId: 103, type: '' },
    { url: 'content-management/add-edit', actionId: 104, type: 'Add' },
    { url: 'content-management/add-edit', actionId: 105, type: 'Edit' },
    { url: 'content-management/delete', actionId: 106, type: '' },

    // security patch add
    { url: 'website/latest-versions', actionId: 115, type: 'plugin' },

    // Web
    { url: 'website/view-milestones', actionId: '', type: 'null' },
    // my evaluation
    { url: 'evaluation/download', actionId: '119', type: 'myEvaluation' },
    // care plan
    { url: 'project-type/list', actionId: 111, type: 'care-plan' },
    { url: 'website/technology-list', actionId: 111, type: 'care-plan' },
    { url: 'website/add-edit', actionId: 112, type: 'care-plan-add' },
    { url: 'website/add-edit', actionId: 113, type: 'care-plan-edit' },
    { url: 'website/list', actionId: 111, type: 'care-plan' },
    { url: 'website/delete', actionId: 114, type: 'care-plan' },

    // web care plan
    { url: 'website/list', actionId: 115, type: 'web-care-plan' },
    { url: 'website/view-milestones', actionId: 115, type: 'web-care-plan' },
    { url: 'website/plugin-list', actionId: 115, type: 'web-care-plan' },
  ];
  private subscriptions: Subscription[] = [];
  private accessControls: MainMenu[] = [];
  private _common = inject(CommonService);

  checkAccess(
    moduleId: number | null,
    submoduleId: number | null,
    actionId: number | null
  ): boolean {
    this.subscriptions.push(
      this._common.accessControls$.subscribe((data) => {
        if (data) {
          this.accessControls = data;
          // if (this.accessControls && this.accessControls.length) {
          //   return this.checkAccess(moduleId, submoduleId, actionId);
          // }
        }
      })
    );

    let checkModule: any;
    if (moduleId) {
      for (let i = 0; i < this.accessControls.length; i++) {
        if (this.accessControls[i].menu_id == moduleId) {
          checkModule = this.accessControls[i];
          break;
        }
      }
    } else if (submoduleId) {
      for (let i = 0; i < this.accessControls.length; i++) {
        if (this.accessControls[i].sub_menu.length) {
          for (let j = 0; j < this.accessControls[i].sub_menu.length; j++) {
            if (this.accessControls[i].sub_menu[j].sub_menu_id == submoduleId) {
              checkModule = this.accessControls[i].sub_menu[j];
              break;
            }
          }
        } else {
          for (let k = 0; k < this.accessControls[i].action.length; k++) {
            if (this.accessControls[i].action[k].action_id == submoduleId) {
              checkModule = this.accessControls[i].action[k];
              break;
            }
          }
        }
      }
    } else if (actionId) {
      for (let i = 0; i < this.accessControls.length; i++) {
        if (this.accessControls[i].sub_menu.length) {
          for (let j = 0; j < this.accessControls[i].sub_menu.length; j++) {
            if (this.accessControls[i].sub_menu[j].action.length) {
              for (
                let k = 0;
                k < this.accessControls[i].sub_menu[j].action.length;
                k++
              ) {
                if (
                  this.accessControls[i].sub_menu[j].action[k].action_id ==
                  actionId
                ) {
                  checkModule = this.accessControls[i].sub_menu[j].action[k];
                  break;
                }
              }
            }
          }
        }
      }
    }

    // if (checkModule != undefined && moduleId != 1) {
    //   this.elementRef.nativeElement.style.opacity =
    //     checkModule.value === true ? '1' : '0';
    //   this.elementRef.nativeElement.style.display =
    //     checkModule.value === true ? '' : 'none';
    // }

    // handle visibility of the element
    if (checkModule != undefined) {
      return checkModule.value;
    } else {
      return false;
    }
  }
}
